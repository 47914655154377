import get from 'get-value';

const getValue = (obj, path, byDefault) => {
  const value = get(obj, path);
  if (!value && byDefault) {
    return byDefault;
  }
  return value;
};

export default getValue;
