/* eslint-disable react-hooks/rules-of-hooks,react/display-name */
import { useRef } from 'react';
import { useZusModalStore, ModalWrapper } from '@/helpers/modal.js';

const YouTubeModal = (Component) => function ({ videoId, isPopin, ...props }) {
  const { setModal1, setModal2 } = useZusModalStore.getState();
  const divRef = useRef();
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  async function onClick() {
    const content = (
      <ModalWrapper onClose={() => (isPopin ? setModal2(null) : setModal1(null))} delay={50}>
        <div className="w-screen h-dvh lg:h-screen overflow-hidden bg-white p-6 lg:p-20" ref={divRef}>
          <div className="rounded-2xl overflow-hidden h-full w-full">
            <iframe style={{ width: '100%', height: '100%' }} src={`${embedUrl}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
          </div>
        </div>
      </ModalWrapper>
    );
    return setModal1(content);
  }

  return (
    <Component openModalYoutube={() => onClick()} {...props} />
  );
};

export default YouTubeModal;
