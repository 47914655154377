/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { cardSkinHome_item$ref } from "./cardSkinHome_item.graphql";
export type zoneSpacesByCityTagQueryVariables = {|
  tags: $ReadOnlyArray<string>,
  lang: string,
  category?: ?string,
  limit?: ?number,
|};
export type zoneSpacesByCityTagQueryResponse = {|
  +spaces: ?$ReadOnlyArray<?{|
    +$fragmentRefs: cardSkinHome_item$ref
  |}>
|};
export type zoneSpacesByCityTagQuery = {|
  variables: zoneSpacesByCityTagQueryVariables,
  response: zoneSpacesByCityTagQueryResponse,
|};
*/


/*
query zoneSpacesByCityTagQuery(
  $tags: [String!]!
  $lang: String!
  $category: String
  $limit: Int
) {
  spaces: getSpacesByTags(lang: $lang, tags: $tags, randomQL: "zoneSpacesByCityTagQuery", limit: $limit) {
    ...cardSkinHome_item_3TvgKh
    id
  }
}

fragment cardSkinHome_item_3TvgKh on Space {
  title(lang: $lang)
  rate
  reviewCounter
  urlByLang(lang: $lang)
  pictures(count: 1) {
    ...image_space_picture
    id
  }
  p: dataByCategory(category: $category) {
    categorySelected
    price {
      amount_excl_tax
    }
  }
}

fragment image_space_picture on SpacePicture {
  nameId
  extension
  predictName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v4 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v5 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Literal",
    "name": "randomQL",
    "value": "zoneSpacesByCityTagQuery"
  },
  {
    "kind": "Variable",
    "name": "tags",
    "variableName": "tags"
  }
],
v6 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
},
v7 = [
  (v4/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "zoneSpacesByCityTagQuery",
    "selections": [
      {
        "alias": "spaces",
        "args": (v5/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpacesByTags",
        "plural": true,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "cardSkinHome_item"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "zoneSpacesByCityTagQuery",
    "selections": [
      {
        "alias": "spaces",
        "args": (v5/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpacesByTags",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reviewCounter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "urlByLang",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "count",
                "value": 1
              }
            ],
            "concreteType": "SpacePicture",
            "kind": "LinkedField",
            "name": "pictures",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extension",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "predictName",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "pictures(count:1)"
          },
          {
            "alias": "p",
            "args": [
              (v6/*: any*/)
            ],
            "concreteType": "DataByCategory",
            "kind": "LinkedField",
            "name": "dataByCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categorySelected",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Price",
                "kind": "LinkedField",
                "name": "price",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount_excl_tax",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb881dbb73f8b847cbbf0c7cbd9e3c64",
    "id": null,
    "metadata": {},
    "name": "zoneSpacesByCityTagQuery",
    "operationKind": "query",
    "text": "query zoneSpacesByCityTagQuery(\n  $tags: [String!]!\n  $lang: String!\n  $category: String\n  $limit: Int\n) {\n  spaces: getSpacesByTags(lang: $lang, tags: $tags, randomQL: \"zoneSpacesByCityTagQuery\", limit: $limit) {\n    ...cardSkinHome_item_3TvgKh\n    id\n  }\n}\n\nfragment cardSkinHome_item_3TvgKh on Space {\n  title(lang: $lang)\n  rate\n  reviewCounter\n  urlByLang(lang: $lang)\n  pictures(count: 1) {\n    ...image_space_picture\n    id\n  }\n  p: dataByCategory(category: $category) {\n    categorySelected\n    price {\n      amount_excl_tax\n    }\n  }\n}\n\nfragment image_space_picture on SpacePicture {\n  nameId\n  extension\n  predictName\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f176bc81be75c3571663c61efbc3670e';

module.exports = node;
