/* eslint-disable no-undef */
import { useEffect, useRef } from 'react';
import create from 'zustand';
import { nanoid } from 'nanoid';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';

const useZusTrackerStore = create((set) => ({
  orevent: null,
  setOrEvent: (what, item, lead) => set({ orevent: `${what}**|**${nanoid()}`, item, lead }),
}));

function getSeoUserData(userData) {
  let seoUserData = {};
  if (userData) {
    seoUserData = {
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      user_id: userData.ref,
      company: userData.company?.name,
    };
  }
  // console.log(seoUserData, "seoUserData");
  return seoUserData;
}

function TrackerComponent({ item, lead }) {
  const orevent = useZusTrackerStore((state) => state.orevent);
  const itemData = useZusTrackerStore((state) => state.item);
  const userData = useZusUserConnectedStore((state) => state.userData);
  const leadData = useZusTrackerStore((state) => state.lead);

  const timeoutRef = useRef(null);

  let seoitemData = {};
  if (itemData) {
    seoitemData = {
      item_name: itemData.title,
      item_id: itemData.ref,
      item_brand: itemData?.host?.ref,
    };
  }

  let seoleadData = {};
  if (leadData) {
    seoleadData = {
      item_category: leadData.category,
      item_id: leadData.spaceRef,
      dates: leadData.dates,
      quantity: leadData.riders,
    };
  }

  useEffect(() => {
    if (!orevent) return;

    // Clear the existing timeout
    clearTimeout(timeoutRef.current);

    let delay = 100; // Default delay

    const oreventValue = orevent.split('**|**')[0];
    const paramsEvent = getSeoUserData(userData);

    if (oreventValue !== 'lead') {
      delay = 2000; // Change delay to 2000 if oreventValue is not 'lead'
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      if (typeof dataLayer !== 'undefined') {
        const eventData = {
          event: oreventValue,
          ...paramsEvent,
        };
        if (itemData) {
          eventData.ecommerce = {
            items: [{
              ...seoitemData,
            }],
          };
        }
        if (leadData) {
          eventData.ecommerce = {
            items: [{
              ...seoleadData,
            }],
          };
        }
        // console.log("TRACK");
        dataLayer.push(eventData);
      }
    }, delay);
    // eslint-disable-next-line
    return () => clearTimeout(timeoutRef.current);
  }, [orevent, userData, item, lead]);

  return null;
}

export {
  TrackerComponent,
  useZusTrackerStore,
  getSeoUserData,
};
