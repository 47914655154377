import {
  useEffect, useReducer,
} from 'react';
import { graphql } from 'react-relay';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/navigation';
import { useRouter as useNextRouter } from 'next/router';
import { lang, useTranslation } from '@/helpers/translate';
import EMglobal from '@/helpers/emitter';
import miniQueryLoader from '@/helpers/miniQueryLoaderv3';

const query = graphql`
  query listenerReloaderByCategory_homeReloaderSpaceQuery($tags: [String!]!, $lang: String!, $category: String, $limit: Int) {
    spaces : getSpacesByTags(lang: $lang, tags: $tags, categories: [$category], randomQL: "listenerReloaderByCategory_homeReloaderSpaceQuery", limit: $limit) {
      ...cardSkinHome_item @arguments(lang: $lang, category: $category)
    }
  }
`;

function ComponentLoader({ data }) {
  const currentLang = lang();

  useEffect(() => {
    EMglobal.emit('spacesbycategory', data?.spaces || []);
  }, [data]);
  return null;
}

const MiniqueryloaderComponent = miniQueryLoader({
  Component: ComponentLoader,
  query,
  providerVariables: ({ currentLang, category }) => ({
    tags: ['home_top'],
    lang: currentLang,
    category,
    limit: 20,
  }),
});

export default function ComponentRoot() {
  const router = useRouter();
  const routerNext = useNextRouter();
  const currentLang = lang();
  const
    { t } = useTranslation();

  const slugs = t('category:slug', {}, { returnObjects: true });
  let categoryslug = routerNext?.query?.route || t('category:slug.meeting');
  // eslint-disable-next-line max-len
  categoryslug = (Object.entries(slugs) || []).find(([, catetrad]) => catetrad === categoryslug)?.[0];

  const [currentCategory, setCurrentCategory] = useReducer((prev, newCate) => {
    if (prev.category === newCate) {
 return {
      category: prev.category,
    };
}
    return {
      category: newCate,
      callId: nanoid(),
    };
  }, {
    category: categoryslug,
  });

  useEffect(() => {
    EMglobal.on('newCategorySelected', setCurrentCategory);
    return () => {
      EMglobal.off('newCategorySelected', setCurrentCategory);
    };
  }, [currentCategory]);

  return !currentCategory?.callId || <MiniqueryloaderComponent
    key={`listerReloaderByCategory${currentCategory?.category}`}
    currentLang={currentLang}
    category={currentCategory?.category}
  />;
}
