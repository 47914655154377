/* eslint-disable no-underscore-dangle */
import CarouselEmbla from '@/core/carousel-embla';
import { SpaceCardSkinCities as CardSkinCities } from '@/core/dynamic';

function SpacesCities({ spaces }) {
  return (
    <section className="relative">
      <div className="h-[350px] md:h-[500px]">
        <CarouselEmbla slidesToScroll={1} loop={false} fromHome align="start" scrollPrev={false}>
          {(spaces || []).map((s) => (
            <div className="embla-slide-cities mx-2 overflow-hidden rounded-lg" key={`cmphomezonespace${s.__id}`}>
              <CardSkinCities
                item={s}
                options={{
                  imageProps: {
                    space: true,
                  },
                }}
              />
            </div>
          ))}
        </CarouselEmbla>
      </div>
    </section>
  );
}

export default SpacesCities;
