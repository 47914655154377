import { useCallback, useState, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Icon from '@/core/icon';

function CarouselEmbla({
  children, slidesToScroll, loop, align, fromCluster, fromHome, onIndexChange, fromSpacePublic, startIndex, enableKeyboardNavigation,
}) {
  const [activeDot, setActiveDot] = useState(startIndex || 0);
  const [totalSlides, setTotalSlides] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...(align ? { align } : {}),
    ...(loop ? { loop } : {}),
    ...(slidesToScroll ? { slidesToScroll } : {}),
    ...(startIndex ? { startIndex } : {}),

  });

  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit();
      emblaApi.on('select', () => {
        const currentIndex = emblaApi.selectedScrollSnap();
        setActiveDot(currentIndex);
        if (onIndexChange) {
          onIndexChange(currentIndex);
        }
      });

      const scrollSnapList = emblaApi.scrollSnapList();
      setTotalSlides(scrollSnapList ? scrollSnapList.length : 0);
    }
  }, [emblaApi, children, startIndex]);

  const scrollPrev = useCallback(
    (e) => {
      e.stopPropagation();
      if (emblaApi) {
        emblaApi.scrollPrev();
      }
    },
    [emblaApi],
  );

  const scrollNext = useCallback(
    (e) => {
      e.stopPropagation();
      if (emblaApi) {
        emblaApi.scrollNext();
      }
    },
    [emblaApi],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (enableKeyboardNavigation && e.keyCode === 37) {
        scrollPrev(e);
      } else if (enableKeyboardNavigation && e.keyCode === 39) {
        scrollNext(e);
      }
    },
    [enableKeyboardNavigation, scrollPrev, scrollNext],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (enableKeyboardNavigation) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [enableKeyboardNavigation, handleKeyDown]);

  return (
    <div className="relative h-full overflow-hidden transition group">
      { (!fromCluster && !fromHome && !fromSpacePublic) && (
      <>
        {' '}
        <div className="absolute z-10 flex items-center h-full ml-4 transition arrow">
          <button aria-label="prev" className="embla__prev" onClick={scrollPrev}>
            <div className="flex items-center justify-center w-8 h-8 transition bg-white rounded-full shadow-button hover:scale-110">
              <Icon
                className="w-5 h-5 mr-1 text-black transform rotate-180"
                name="next"
              />
            </div>
          </button>
        </div>
        <div className="absolute right-0 z-10 flex justify-end h-full mr-4 transition arrow">
          <button aria-label="next" className="embla__next" onClick={scrollNext}>
            <div className="flex items-center justify-center w-8 h-8 transition bg-white rounded-full shadow-button hover:scale-110">
              <Icon
                className="w-5 h-5 ml-1 text-black"
                name="next"
              />
            </div>
          </button>
        </div>
      </>
      ) }
      {fromHome && (
      <>
        {' '}
        <div className="absolute z-10 flex items-center h-full ml-6 transition arrow">
          <button aria-label="prev" className="embla__prev" onClick={scrollPrev}>
            <div className="flex items-center justify-center w-10 h-10 transition bg-black rounded-full shadow-button hover:scale-110">
              <Icon
                className="w-6 h-6 mr-1 transform rotate-180 text-white-dark"
                name="next"
              />
            </div>
          </button>
        </div>
        <div className="absolute right-0 z-10 flex justify-end h-full mr-4 transition arrow">
          <button aria-label="next" className="embla__next" onClick={scrollNext}>
            <div className="flex items-center justify-center w-10 h-10 transition bg-black rounded-full shadow-button hover:scale-110">
              <Icon
                className="w-6 h-6 ml-1 text-white-dark"
                name="next"
              />
            </div>
          </button>
        </div>
      </>
      )}
      {fromSpacePublic && (
      <>
        {' '}
        <div className="absolute z-10 flex items-center h-full ml-8 transition arrow">
          <button aria-label="prev" className="embla__prev" onClick={scrollPrev}>
            <div className="flex items-center justify-center w-12 h-12 transition bg-black border rounded-full shadow-button border-dark hover:bg-black-light hover:scale-110">
              <Icon
                className="w-5 h-5 mr-1 text-white transform rotate-180"
                name="next"
              />
            </div>
          </button>
        </div>
        <div className="absolute right-0 z-10 flex justify-end h-full mr-8 transition arrow">
          <button aria-label="next" className="embla__next" onClick={scrollNext}>
            <div className="flex items-center justify-center w-12 h-12 transition bg-black border rounded-full shadow-button border-dark hover:bg-black-light hover:scale-110">
              <Icon
                className="w-5 h-5 ml-1 text-white transform"
                name="next"
              />
            </div>
          </button>
        </div>
      </>
      )}
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">{children}</div>
        </div>
      </div>
      {(!fromCluster && !fromHome && !fromSpacePublic) && (
      <div className="absolute bottom-0 w-full flex justify-center mb-3 gap-x-[5px]">
        {Array.from({ length: totalSlides }, (_, index) => (
          <div
            key={index}
            className={`w-[6px] h-[6px] rounded-full bg-white cursor-pointer ${
              index === activeDot ? 'scale-125' : 'bg-opacity-60'
            }`}
            onClick={() => emblaApi.scrollTo(index)}
          />
        ))}
      </div>
      )}
      {fromSpacePublic && (
      <div className="absolute flex justify-center w-full mb-6 text-sm top-8 text-dark gap-x-2">
        <div className="">{activeDot + 1} / {totalSlides}</div>
      </div>
      )}
      {fromCluster
       && (
       <div className="w-full border-t border-t-white-dark4 lg:mt-2">
         <div className="flex">
           <button
             className="flex items-center justify-center w-1/2 transition border-r rounded-none group hover:bg-blue-light border-white-dark4"
             onClick={scrollPrev}
           >
             <Icon
               className="h-5 mr-1 text-black rotate-180 w-7 group-hover:text-blue"
               name="next"
             />
           </button>
           <button
             className="flex items-center justify-center w-1/2 h-10 transition rounded-none group hover:bg-blue-light"
             onClick={scrollNext}
           >
             <Icon
               className="h-5 ml-1 text-black w-7 group-hover:text-blue"
               name="next"
             />
           </button>
         </div>
       </div>
       )}
    </div>
  );
}

export default CarouselEmbla;
