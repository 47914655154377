/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { cardSkinHome_item$ref } from "./cardSkinHome_item.graphql";
import type { zoneSpaceData$ref } from "./zoneSpaceData.graphql";
export type homeQueryVariables = {|
  tags: $ReadOnlyArray<string>,
  lang: string,
  category?: ?string,
  limit?: ?number,
|};
export type homeQueryResponse = {|
  +spaces: ?$ReadOnlyArray<?{|
    +$fragmentRefs: cardSkinHome_item$ref
  |}>,
  +categories: ?$ReadOnlyArray<?{|
    +$fragmentRefs: zoneSpaceData$ref
  |}>,
  +spacesParis: ?$ReadOnlyArray<?{|
    +$fragmentRefs: cardSkinHome_item$ref
  |}>,
|};
export type homeQuery = {|
  variables: homeQueryVariables,
  response: homeQueryResponse,
|};
*/


/*
query homeQuery(
  $tags: [String!]!
  $lang: String!
  $category: String
  $limit: Int
) {
  spaces: getSpacesByTags(lang: $lang, tags: $tags, categories: [$category], limit: $limit) {
    ...cardSkinHome_item_3TvgKh
    id
  }
  categories: getCategories {
    ...zoneSpaceData
    id
  }
  spacesParis: getSpacesByTags(lang: $lang, tags: ["home_paris"], limit: 10) {
    ...cardSkinHome_item_3TvgKh
    id
  }
}

fragment cardSkinHome_item_3TvgKh on Space {
  title(lang: $lang)
  rate
  reviewCounter
  urlByLang(lang: $lang)
  pictures(count: 1) {
    ...image_space_picture
    id
  }
  p: dataByCategory(category: $category) {
    categorySelected
    price {
      amount_excl_tax
    }
  }
}

fragment image_space_picture on SpacePicture {
  nameId
  extension
  predictName
}

fragment zoneSpaceData on Category {
  slug
  details
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tags"
},
v4 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v5 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "categories.0",
        "variableName": "category"
      }
    ],
    "kind": "ListValue",
    "name": "categories"
  },
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "tags",
    "variableName": "tags"
  }
],
v6 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
},
v7 = [
  {
    "args": [
      (v6/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "FragmentSpread",
    "name": "cardSkinHome_item"
  }
],
v8 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "limit",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "tags",
    "value": [
      "home_paris"
    ]
  }
],
v9 = [
  (v4/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": (v9/*: any*/),
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reviewCounter",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v9/*: any*/),
    "kind": "ScalarField",
    "name": "urlByLang",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "count",
        "value": 1
      }
    ],
    "concreteType": "SpacePicture",
    "kind": "LinkedField",
    "name": "pictures",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "extension",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "predictName",
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "storageKey": "pictures(count:1)"
  },
  {
    "alias": "p",
    "args": [
      (v6/*: any*/)
    ],
    "concreteType": "DataByCategory",
    "kind": "LinkedField",
    "name": "dataByCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categorySelected",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Price",
        "kind": "LinkedField",
        "name": "price",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount_excl_tax",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "homeQuery",
    "selections": [
      {
        "alias": "spaces",
        "args": (v5/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpacesByTags",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": "categories",
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "getCategories",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "zoneSpaceData"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "spacesParis",
        "args": (v8/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpacesByTags",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "homeQuery",
    "selections": [
      {
        "alias": "spaces",
        "args": (v5/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpacesByTags",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      {
        "alias": "categories",
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "getCategories",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "spacesParis",
        "args": (v8/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpacesByTags",
        "plural": true,
        "selections": (v11/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc401ea6289f42afefbc625b19deb1bf",
    "id": null,
    "metadata": {},
    "name": "homeQuery",
    "operationKind": "query",
    "text": "query homeQuery(\n  $tags: [String!]!\n  $lang: String!\n  $category: String\n  $limit: Int\n) {\n  spaces: getSpacesByTags(lang: $lang, tags: $tags, categories: [$category], limit: $limit) {\n    ...cardSkinHome_item_3TvgKh\n    id\n  }\n  categories: getCategories {\n    ...zoneSpaceData\n    id\n  }\n  spacesParis: getSpacesByTags(lang: $lang, tags: [\"home_paris\"], limit: 10) {\n    ...cardSkinHome_item_3TvgKh\n    id\n  }\n}\n\nfragment cardSkinHome_item_3TvgKh on Space {\n  title(lang: $lang)\n  rate\n  reviewCounter\n  urlByLang(lang: $lang)\n  pictures(count: 1) {\n    ...image_space_picture\n    id\n  }\n  p: dataByCategory(category: $category) {\n    categorySelected\n    price {\n      amount_excl_tax\n    }\n  }\n}\n\nfragment image_space_picture on SpacePicture {\n  nameId\n  extension\n  predictName\n}\n\nfragment zoneSpaceData on Category {\n  slug\n  details\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3aab4d5f8f5284457277567bfb36b63';

module.exports = node;
