import {
 useState, useEffect, Fragment,
 useReducer, useRef,
 useImperativeHandle,
} from 'react';
import { graphql } from 'react-relay';
import { Listbox, Transition } from '@headlessui/react';
import { compiler } from 'markdown-to-jsx';
import { nanoid } from 'nanoid';
import { Markdown, useTranslation, lang } from '@/helpers/translate';
import ZoneSpacesCities from './zoneSpacesCities';
import EMglobal from '@/helpers/emitter';
import miniQueryLoader from '@/helpers/miniQueryLoaderv3';

const queryQL = graphql`
  query zoneSpacesByCityTagQuery($tags: [String!]!, $lang: String!, $category: String, $limit: Int) {
    spaces : getSpacesByTags(lang: $lang, tags: $tags, randomQL: "zoneSpacesByCityTagQuery", limit: $limit) {
      ...cardSkinHome_item @arguments(lang: $lang, category: $category)
    }
  }
`;

function UseQueryLoadSpaceByCityTag({
 data, setCurrentData, citySlug, category, loadQuery, fwdRef,
}) {
  const [currentCate, setCate] = useReducer((state, newData) => {
      if (typeof newData === 'string') {
        return {
          category: newData,
          changed: nanoid(),
          citySlug: state?.citySlug,
        };
      } if (newData?.citySlug) {
        return {
          category: state?.category,
          changed: nanoid(),
          citySlug: newData?.citySlug,
        };
      }
      return {
        ...state,
      };
    }, {
    category,
    changed: null,
    citySlug,
  });
  const langCurrent = lang();

  useImperativeHandle(fwdRef, () => ({
    setCate,
  }), [currentCate]);

  useEffect(() => {
    if (data) {
      setCurrentData(data.spaces);
    }
  }, [data]);

  useEffect(() => {
    if (currentCate?.changed) {
      loadQuery({
        lang: langCurrent,
        tags: [`home_${currentCate.citySlug}`],
        category: currentCate.category,
        limit: 20,
      }, { fetchPolicy: 'network-only' });
    }
    EMglobal.on('newCategorySelected', setCate);
    return () => {
      EMglobal.off('newCategorySelected', setCate);
    };
  }, [currentCate]);

  return null;
}

const MiniQLoaderpaceByCityTag = miniQueryLoader({
  initOnly: true,
  Component: UseQueryLoadSpaceByCityTag,
  query: queryQL,
});

function ZoneSpacesByCityTag({ spaceLists, category }) {
  const componentLoader = useRef();
  const [currentSpaceLists, setSpacesLists] = useState(spaceLists || []);
  const [currentCity, setCity] = useState({
 slug: 'paris', text: 'Paris', url: '/fr/france/paris', first: true,
});

  const { t } = useTranslation();

  const [citieslistData] = useState(t('guides:cities', {}, { returnObjects: true })?.menu1);
  const [citieslist] = useState(Object.entries(citieslistData?.children || {}).reduce((acc, [itemKey, itemLink]) => {
    const itemMatch = itemLink.match(/\[(.*)\]\((.*)\)/);
    const text = itemMatch?.[1];
    const url = itemMatch?.[2];
    if (text && citieslistData?.icon?.[itemKey]) {
      return [
        ...acc,
        {
          slug: citieslistData?.icon?.[itemKey],
          text,
          url,
        },
      ];
    }
      return acc;
  }, []));

  useEffect(() => {
    if (!currentCity?.first) {
      componentLoader?.current?.setCate({
        citySlug: currentCity.slug,
      });
    }
  }, [currentCity]);

  function renderItemList() {
    const children = citieslist.map(({ slug, text, url }) => (
        <Listbox.Option className="px-2 py-1 mx-2 text-2xl rounded-lg cursor-pointer text-dark-deep hover:text-black hover:bg-white-dark" key={`homepageitembycity${text}`} value={{ slug, text, url }} as={Fragment}>
          {() => (<li>{text}</li>)}
        </Listbox.Option>
    ));
    return (
      <Listbox onChange={setCity}>
         <div className="relative mt-1">
        <Listbox.Button className="flex items-center px-3 py-1 my-2 text-2xl text-red bg-red-light2 hover:bg-opacity-20 bg-opacity-10">
          {currentCity.text}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-64 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {children}
          </Listbox.Options>
        </Transition>
         </div>
      </Listbox>
    );
  }
  return (
    <>
    <section className="relative flex flex-col h-full pt-16 bg-white lg:pt-0 lg:px-0 xl:pl-56 2xl:pl-80 lg:-top-20 md:h-500">
      <div className="hidden lg:block absolute max-w-5xl left-30v -top-20 h-[460px] w-[460px] bg-red bg-opacity-[8%] rounded-full" />
      <div className="grid items-center grid-cols-1 z-5 md:grid-cols-12">
        <div className="px-8 pb-6 md:col-span-5 lg:col-span-3 md:pr-3 md:ml-8 lg:px-0">
          <div className="max-w-xs">
            <h3 className="text-3xl font-medium leading-16">
              <Markdown>{t('home:cities.title')}</Markdown>
              {renderItemList()}
              {/* <span className="text-red bg-red-light px-[6px] py-[8px] rounded-xl">Paris</span> */}
            </h3>
            <p className="pt-2 pr-10 text-lg text-dark">{t('home:cities.subtitle')}</p>
            <div className="flex items-center pt-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
              </svg>
              <a href={currentCity.url} className="ml-2 text-red pb-[1px] border-b-2">{t('home:cities.link')}</a>
            </div>
          </div>
        </div>
        <div className="relative pl-8 lg:pl-0 md:col-span-7 lg:col-span-9">
          <ZoneSpacesCities spaces={currentSpaceLists} />
        </div>
      </div>
    </section>
    <MiniQLoaderpaceByCityTag fwdRef={componentLoader} setCurrentData={setSpacesLists} citySlug={currentCity.slug} {...{ category }} />
    </>
  );
}

export default ZoneSpacesByCityTag;
